@mixin translate($transformX, $transformY) {
  -moz-transform: translate($transformX, $transformY);
  -o-transform: translate($transformX, $transformY);
  -ms-transform: translate($transformX, $transformY);
  -webkit-transform: translate($transformX, $transformY);
  transform: translate($transformX, $transformY);
}

@mixin rotate($rotate) {
  -moz-transform: rotate($rotate);
  -o-transform: rotate($rotate);
  -ms-transform: rotate($rotate);
  -webkit-transform: rotate($rotate);
  transform: rotate($rotate);
}

@mixin translateY($translateY) {
  -moz-transform: translateY($translateY);
  -o-transform: translateY($translateY);
  -ms-transform: translateY($translateY);
  -webkit-transform: translateY($translateY);
  transform: translateY($translateY);
}

@mixin translateX($translateX) {
  -moz-transform: translateX($translateX);
  -o-transform: translateX($translateX);
  -ms-transform: translateX($translateX);
  -webkit-transform: translateX($translateX);
  transform: translateX($translateX);
}

@mixin translateZ($translateZ) {
  -moz-transform: translateZ($translateZ);
  -o-transform: translateZ($translateZ);
  -ms-transform: translateZ($translateZ);
  -webkit-transform: translateZ($translateZ);
  transform: translateZ($translateZ);
}

@mixin scale($scale) {
  -moz-transform: scale($scale);
  -o-transform: scale($scale);
  -ms-transform: scale($scale);
  -webkit-transform: scale($scale);
  transform: scale($scale);
}

@mixin transition($transition) {
  transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
}

@mixin grayscale($filter) {
  filter: grayscale($filter);
  -webkit-filter: grayscale($filter);
  -moz-filter: grayscale($filter);
  -o-filter: grayscale($filter);
  -ms-filter: grayscale($filter);
}

//placeholder
@mixin placeholder {
  &:-moz-placeholder {
    @content;
  }
  &::-ms-placeholder {
    @content;
  }
  &::-o-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background: transparent !important;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent !important;
    border-radius: 0.4rem;
    //border: 0.2rem solid #fff;
    opacity: 0;
  }
  &::-webkit-scrollbar-thumb:hover {
    opacity: 1;
    background: #afb2c0 !important;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background: #afb2c0 !important;
    }
  }
}

body {
  &::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 0.4rem;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
    opacity: 1;
    background: #afb2c0;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }
}
//Border Radius
@mixin border-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

@function strip-unit($num) {
  @return $num / 10;
}

@mixin rem-fallback($property, $values...) {
  $max: length($values);
  $pxValues: '';
  $remValues: '';

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $pxValues: #{$pxValues + $value * 16}px;

    @if $i < $max {
      $pxValues: #{$pxValues + ' '};
    }
  }

  @for $i from 1 through $max {
    $value: strip-unit(nth($values, $i));
    $remValues: #{$remValues + $value}rem;

    @if $i < $max {
      $remValues: #{$remValues + ' '};
    }
  }

  //#{$property}: $pxValues;
  #{$property}: $remValues;
}

@mixin line-height($fontSize, $lineHeight) {
  line-height: $lineHeight/$fontSize;
}

// @mixin custom-tag($border, $border-radius, $background) {
//   border: $border;
//   border-radius: $border-radius;
//   background: $background;
//   font-size: $font-size;
//   color: $color;
//   text-transform: uppercase;
//   padding: 0.8rem 1.6rem;
//   color: $black1;
//   font-size: 1.2rem;
//   line-height: 1.6rem;
// }
@mixin custom-tag(
  $border,
  $border-radius,
  $background,
  $color: $black1,
  $font-size: 1.2rem
) {
  border: $border;
  border-radius: $border-radius;
  background: $background;
  color: $color;
  font-size: $font-size;
  text-transform: uppercase;
  padding: 0.8rem 1.6rem;
  line-height: 1.6rem;
}

@mixin user-avatar(
  $font-size,
  $font-weight,
  $color,
  $background,
  $width,
  $height,
  $min-width
) {
  font-size: $font-size;
  line-height: 1.5;
  font-weight: $font-weight;
  color: $color;
  background-color: $background;
  width: $width;
  min-width: $min-width;
  height: $height;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
