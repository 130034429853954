@import '../../assets/scss/fonts';

// Natasha animated icon
.wizardAvatar {
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: 50%;
  left: 16px;
  transform: translate(-50%, -14%);
  border-radius: 100px;
  z-index: 1;
  &.md {
    width: 2.6rem !important;
    height: 2.6rem !important;
    position: relative;
    left: 4.2rem !important;
    top: 2.8rem !important;
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 200px;
    background: $purple;
    display: block;

    &:nth-child(1) {
      width: 200%;
      height: 200%;
      z-index: 1;
      box-shadow:
        0 0 16px #b388ff,
        0 0 0 10px #f3ebff;
      animation: pulse-ring 2s cubic-bezier(0.215, 0.61, 0.155, 0.1) infinite;
    }
    &:nth-child(2) {
      width: 100%;
      height: 100%;
      z-index: 2;
      border: 1px solid #000;
      box-shadow:
        0 0 0px #b388ff,
        0 0 0 0px #f3ebff;
      animation: pulse-dot 2.5s cubic-bezier(0.455, 0.3, 0.515, 0.955) -0.1s infinite
        alternate-reverse;
    }

    @keyframes pulse-ring {
      0% {
        transform: translate(-50%, -50%) scale(0.5);
      }
      80%,
      100% {
        opacity: 0;
      }
    }

    @keyframes pulse-dot {
      0% {
        transform: translate(-50%, -50%) scale(0.88);
      }
      50% {
        transform: translate(-50%, -50%) scale(1);
      }
      100% {
        transform: translate(-50%, -50%) scale(0.95);
      }
    }
  }

  &.idleAnimation {
    span {
      width: 50%;
      height: 50%;
      border: 1px solid #000;
      &:nth-child(1) {
        animation: natasha_idle2 1s infinite ease-in-out alternate;
      }
      &:nth-child(2) {
        animation: natasha_idle 1s infinite ease-in-out alternate;
      }

      @keyframes natasha_idle {
        0% {
          box-shadow:
            0 0 0 4px #b388ff,
            0 0 0 8px #f3ebff;
        }
        100% {
          box-shadow:
            0 0 0 8px #b388ff,
            0 0 0 11px #f3ebff;
        }
      }
      @keyframes natasha_idle2 {
        0% {
          box-shadow:
            0 0 0 8px #f3ebff,
            0 0 0 12px #f3ebff;
        }
        100% {
          box-shadow:
            0 0 0 4px #f3ebff,
            0 0 0 8px #f3ebff;
        }
      }
    }
  }

  &.stopAnimation {
    span {
      width: 100%;
      height: 100%;
      border: 8px solid #000;
      &:nth-child(1) {
        animation: none;
        box-shadow:
          0 0 0 16px #b388ff,
          0 0 0 40px #f3ebff;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.natasha-buildcard-banner {
  .wizardAvatar {
    transform: translate(0, 0);
    left: 0.4rem;
    min-width: 1.4rem;
  }
}

.sidenav-visible {
  .wizardAvatar {
    transform: translate(-50%, -50%);
    left: 0.9rem;
  }
  .natasha-buildcard-banner {
    .wizardAvatar {
      transform: translate(-50%, 0);
      left: 1.2rem;
    }
  }
}

// Three dots loader
.three-dots {
  cursor: pointer;
  .be-vertical-dots {
    font-size: 2rem;
    font-weight: 700;
  }
}

.row-container {
  .three-dots {
    width: 14.8rem;
    justify-content: end;
    display: flex;
    @include bp(sm-max) {
      display: none;
    }
    .be-vertical-dots {
      font-size: 1.6rem;
      display: flex;
      padding: 1.4rem;
    }
  }
}

// Date box
.date-box {
  font-size: 1rem;
  font-weight: 500;
  @include line-height(10, 16);
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 2px;
  border: 1px solid $grey8;
  padding: 0.4rem 0.8rem;
  @include bp(sm-max) {
    width: auto;
  }
}

// Color dot
.dot {
  display: block;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  background-color: $grey10;
  @extend .transition;
  cursor: pointer;
  margin-right: 0.4rem;
  &:last-child {
    margin: 0;
  }
  &.active {
    background-color: $purple1;
  }
  &.md {
    display: inline-flex;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    background: $grey2;
  }
  &.lg {
    display: inline-flex;
    width: 2.4rem;
    height: 2.4rem;
    background: $grey2;
    border-radius: 100%;
    margin-right: 1.2rem;
    @include bp(sm-max) {
      width: 1.6rem;
      height: 1.6rem;
      margin-right: 0.6rem;
    }
  }
  &.sm {
    display: inline-flex;
    width: 1.2rem;
    height: 1.2rem;
    background: $grey2;
    border-radius: 100%;
    margin-right: 0.8rem;
  }
  &.sx {
    display: inline-flex;
    width: 0.8rem;
    height: 0.8rem;
    background: $grey2;
    border-radius: 100%;
  }
}

// Custom Pagination

.custom-pagination-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: 4rem;
  @include bp(smd-max) {
    margin-top: 3rem;
  }
  p {
    margin-bottom: 0;
    @include bp(sm-max) {
      margin-bottom: 1rem;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @extend .no-bullets;
    li {
      margin-bottom: 0.5rem;
      margin-right: 0.2rem;
      &:first-child {
        .MuiPaginationItem-previousNext {
          &::after {
            @include rotate(180deg);
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &[disabled],
      &.disabled {
        cursor: not-allowed;
        .page-no {
          color: $grey12;
          pointer-events: none;
          &.is-arrow {
            color: $grey12;
          }
        }
      }
      .MuiPaginationItem-previousNext {
        &.Mui-disabled {
          color: $grey12;
          &::after {
            color: $grey12;
          }
        }
        &::after {
          content: '\77';
          font-family: $enterpriseFont;
          color: $purple3;
        }
        svg {
          display: none;
        }
      }
      .MuiPaginationItem-page,
      .page-no {
        height: 3.2rem;
        min-width: 3.2rem;
        padding: 0 1.2rem;
        cursor: pointer;
        border-radius: 0.3rem;
        font-size: 1.4rem;
        @include line-height(14, 20);
        color: $black1;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @extend .transition;
        margin: 0;
        &:hover:not(.is-arrow),
        &.active,
        &.Mui-selected {
          background-color: $purple3;
          color: $white;
        }
        &.is-arrow {
          color: $purple1;
          &.prev {
            em[class^='be-'] {
              @include rotate(180deg);
            }
          }
        }

        em[class^='be-'] {
          line-height: 0;
        }
      }
    }
  }
}

.tag-result {
  .custom-pagination-wrapper {
    margin-top: 1.6rem;
  }
}
.credits-history {
  .custom-pagination-wrapper {
    background-color: $white;
    align-items: center;
    margin-top: 0;
    padding: 2rem 2.4rem;
    border-top: 0.1rem solid $grey2;
    @include bp(sm-max) {
      padding: 2rem;
    }
  }
}
.plan-payments {
  .custom-pagination-wrapper {
    align-items: center;
    border: 1px solid $grey2;
    background-color: $white;
    padding: 0.8rem 1.5rem;
    border-radius: 0rem 0rem 1.2rem 1.2rem;
    margin-top: -0.7rem;
    @include bp(sm-max) {
      justify-content: center !important;
    }
    .MuiPagination-ul {
      margin-bottom: 0;
      li {
        margin-bottom: 0;
        .MuiButtonBase-root {
          min-height: 3.2rem;
          border: 0;
          @include bp(sm-max) {
            min-width: 3rem;
            padding: 0 1rem;
          }
          &:after {
            display: none;
          }
          svg {
            display: block;
            width: 2.3rem;
            height: 2.3rem;
          }
        }
        &:first-child {
          .MuiButtonBase-root {
            width: 3.3rem;
            color: $purple;
          }
        }
        &:last-child {
          .MuiButtonBase-root {
            width: 3.1rem;
            color: $purple;
          }
        }
      }
    }
  }
}

// Tabs menu || tab buttons
.tab-menu {
  .MuiTabs-root {
    min-height: 4rem;
    .MuiTabs-fixed {
      .MuiButtonBase-root {
        min-width: auto;
        padding: 0.8rem 0 0.8rem;
        margin: 0 4.8rem 0 0;
        min-height: 4rem;
        text-transform: capitalize;
        font-size: 1.6rem;
        color: $black1;
        font-weight: 400;
        @include line-height(16, 24);
        &:last-child {
          margin-right: 0;
        }
        .MuiTouchRipple-root {
          color: $black1;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 2.4rem;
        }
        &:hover:not(.Mui-selected) {
          color: $purple;
          &::after {
            left: 0;
            right: 0;
            width: 100%;
          }
        }
        &:not(.Mui-selected) {
          &::after {
            content: '';
            position: absolute;
            left: 50%;
            right: 50%;
            bottom: 0;
            width: 0;
            height: 0.2rem;
            background-color: $purple1;
            @extend .transition;
          }
        }
        &.Mui-selected {
          color: $purple1;
          font-weight: 500;
        }
        &.Mui-disabled {
          pointer-events: inherit;
        }
      }
      //.MuiTabs-indicator {}
    }
  }
}

.hamburger-icon {
  position: relative;
  background-color: $purple1;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  color: $white;
  &:before {
    font-weight: 600 !important;
  }
}
.mob-header {
  display: none;
  align-items: center;
  @include bp(md-max) {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
  }

  .hamburger {
    position: relative;
    width: 1.5rem;
    height: 1em;
    color: $white;
    transition: all 0.3s ease;
    cursor: pointer;
    font-size: 1.5rem;

    &-line {
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.1rem;
      border-radius: 0.125em;
      background: currentColor;
      transition: inherit;

      &:nth-child(1) {
        top: 0.125em;
        width: 0.625em;
      }

      &:nth-child(2) {
        top: 0.6rem;
        width: 1.6rem;
      }

      &:nth-child(3) {
        top: 1.2rem;
        width: 1.2rem;
      }
    }
  }
  .site-logo {
    font-size: 3rem;
    display: flex !important;
    color: $purple1;
    margin-left: 0.3rem;
  }
}
.table-wrapper {
  .initials {
    height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    border-radius: 50%;
    background: $purple2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    font-size: 1rem;
    color: $black2;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    margin-right: 0.8rem;
    line-height: 2.4rem;
    img {
      margin: 0 !important;
      top: 0 !important;
      border-radius: 6rem;
    }
  }
  .three-dots {
    margin: 0 1.2rem 0 0;
    display: inline-flex;
  }
}
.space-list-table {
  .has-initials {
    .initials {
      margin-left: -0.5rem;
      margin-right: 0;
    }
  }
}

.total-count {
  font-size: 1.4rem;
  color: $trueGrey;
  font-weight: 400;
  margin-bottom: 1rem;
  display: block;
}
