.tab-page-section {
  .page-container {
    padding-right: 0;
    padding-bottom: 0;
    .timeline-wrapper {
      display: flex;
      justify-content: space-between;
      @include bp(smd-max) {
        flex-flow: column;
      }
    }
    .main-page-header {
      padding-right: 2.4rem;
      margin-bottom: 1.5rem;
      @include bp(sm-max) {
        padding-right: 0;
      }
    }
    > .page-filters-wrapper {
      padding-right: 2.4rem;
      margin-bottom: 1.6rem;
      .right-filter {
        .btn-three-dots {
          min-width: 4.8rem;
          height: 4.8rem;
          .icon {
            display: flex;
            font-size: 2rem;
          }
        }
      }
    }
    .page-content {
      padding-top: 2.4rem;
      padding-right: 2.4rem;
      padding-bottom: 0;
      width: 100%;
      max-width: 100%;
      @include bp(sm-max) {
        padding-right: 0;
      }
      .page-filters-wrapper {
        margin-bottom: 2.4rem;
        .left-filter {
          .form-group {
            &.search-container {
              padding: 0;
              width: 30.4rem;
              max-width: 100%;
              margin: 0;
              @include bp(sm-max) {
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  .activities-wrapper {
    margin: 0;
    border: 1px solid $grey2;
    border-radius: 0.8rem;
    &.no-border {
      border: none !important;
      border-radius: 0 !important;
    }
    margin: 0;
  }  
  .activity-list-box {
    background: $white;
    padding: 0;
    padding-bottom: 0;
    box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.15);
    &:first-child {
      border-radius: 0.8rem 0.8rem 0 0;
    }
    &:last-child {
      padding-bottom: 1.6rem;
      border-radius: 0 0 0.8rem 0.8rem;
    }
    &.scrollable {
      max-height: calc(100vh - 33rem);
      overflow: auto;
      @include custom-scroll;
      @include bp(smd-max) {
        max-height: inherit;
        overflow: visible;
      }
    }
    .activity-group {
      margin-bottom: 1.6rem;
      &:last-child {
        margin: 0;
      }
    }
    h6 {
      padding: 0.8rem 1.6rem;
      border-bottom: 0.1rem solid $grey3;
      margin: 0;
    }
    .recent-activity {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      max-height: 100%;
      // overflow: auto;
      // @include custom-scroll;
      &.no-scroll {
        max-height: inherit;
        overflow: inherit;
      }
      @include bp(smd-max) {
        display: flex;
        flex-flow: column;
      }
      .user-link {
        padding: 1.6rem 0;
        display: flex;
        //align-items: center;
        font-size: 1.4rem;
        @include line-height(12, 20);
        letter-spacing: 0.01em;
        font-weight: 400;
        //cursor: pointer;
        position: relative;
        padding-right: 30px;

        .menu-trigger {
          position: absolute;
          right: 0;
          top: 50%;
          cursor: pointer;
          @include translateY(-50%);
          line-height: 0;
          display: inline-block;
          .trigger-icon {
            display: inline-block;
            line-height: 0;
            color: $grey4;
            font-size: 24px;
            @include rotate(90deg);
          }
        }

        .initials,
        .user-avatar {
          @include user-avatar(
            1.4rem,
            500,
            $black1,
            $lightPurple,
            3.6rem,
            3.6rem,
            3.6rem
          );
          img {
            border-radius: 50%;
          }
        }
        .user-name {
          padding-left: 1.2rem;
        }
        .user-detail {
          padding-left: 1.2rem;
          letter-spacing: 0.014rem;
          color: $black;
          font-weight: normal;
          .basic-info {
            position: relative;
            .htmlContent {
              strong {
                font-weight: 400;
              }
            }
            &:hover {
              .profile-tooltip-wrap {
                display: block;
              }
            }
          }
          .user-name {
            padding-left: 0;
            color: $purple1;
          }
        }
        .time {
          color: $grey5;
          position: relative;
          padding-left: 10px;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 3px;
            width: 3px;
            display: block;
            background: $grey5;
            border-radius: 50%;
          }
        }
      }
      .infinite-scroll-component {
        overflow-x: hidden !important;
      }
    }
  }
  &.spaces-team-page {
    .page-container {
      .main-page-header {
        margin-bottom: 1.6rem;
      }
    }
  }
}

// Right side summary
.summary,
.tag-result {
  position: relative;
  width: 33.6rem;
  height: auto;
  min-height: calc(100vh - 21.7rem);
  border-left: 1px solid $grey2;
  padding: 2.4rem;
  font-size: 1.4rem;
  color: $black1;
  background: $white;

  @include line-height(14, 20);
  &.scrollable {
    height: calc(100vh - 22rem);
    overflow: auto;
    @include custom-scroll;
    @include bp(smd-max) {
      height: auto;
      overflow: visible;
    }
  }
  // @include bp(smd-max) {
  //   width: 100%;
  //   max-width: calc(100% - 2.4rem);
  //   border: 1px solid $grey2;
  //   margin-bottom: 2.4rem;
  // }
  // @include bp(sm-max) {
  //   max-width: 100%;
  //   margin-top: 1.5rem;
  // }
  .title-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0.8rem 0;
    .title {
      padding: 0;
    }
    .MuiButtonBase-root {
      width: 2.4rem;
      height: 2.4rem;
      min-width: 2.4rem;
      font-size: 2rem;
      font-weight: 400;
      padding: 0;
      &.xs-btn,
      &.is-icon-btn {
        width: 2.4rem;
        height: 2.4rem;
        font-size: 1.6rem;
        line-height: 1;
        background: $purple6;
        transition: none;
        &:focus,
        &:focus-visible,
        &:visited {
          background: inherit;
          transition: none;
          outline: none;
        }
      }
    }
    .MuiPaper-root {
      .MuiList-root {
        margin-top: 0.4rem;
      }
    }
  }
  .title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 0 1.2rem 0;
    color: $black1;
    letter-spacing: 0.1rem;
    display: flex;
    &.space {
      padding: 0 0 0.8rem 0;
    }
    em {
      font-size: 1.6rem;
      display: flex;
      margin-right: 0.6rem;
      position: relative;
      top: -0.1rem;
    }
  }

  .credits {
    display: flex;
    justify-content: space-between;
    padding: 0 0 2.4rem;
    @include bp(smd-max) {
      width: 100%;
      padding: 0 0 1.6rem 0;
    }
    .credit-balance {
      .count {
        font-size: 3.2rem;
        @include line-height(32, 40);
        font-weight: 500;
        padding: 0 0 1.6rem;
        @include bp(smd-max) {
          padding: 0;
        }
      }
    }
    .request-credits {
      a {
        color: $purple1;
        text-decoration: underline;
        font-size: 1.2rem;
        &:hover {
          cursor: pointer;
          text-decoration: none;
        }
      }
    }
  }
  .content-row {
    border-top: 0.1rem solid $grey2;
    padding: 1.5rem 0;
    position: relative;
    &:first-child {
      border-top: 0;
    }
    .corner-link {
      position: absolute;
      right: 0;
      top: 2rem;
      line-height: 0;
      font-size: 1.6rem;
      color: $grey11;
      cursor: pointer;
    }
    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    @include bp(smd-max) {
      width: 100%;
      padding: 1.6rem 0;
    }
    .owner-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .owner {
        .user-avatar {
          display: flex;
          align-items: center;
          max-width: 13.6rem;
          .initials {
            font-size: 1.4rem;
            @include line-height(14, 20);
          }
          img {
            margin-right: 0.8rem;
          }
        }
      }
      .member {
        text-align: right;
        .icons-wrap {
          display: inline-flex;
          align-items: center;
          .icon {
            width: 3.6rem;
            height: 3.6rem;
            background: $grey6;
            border-radius: 2.4rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 0.1rem solid $white;
            margin-left: -1rem;
            font-size: 1.4rem;
            font-weight: 500;
            color: $black2;
            cursor: pointer;
          }
        }
      }
    }
    &.credit-consumed-wrap {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .credit-consume {
        .progress-wrap {
          position: relative;
          .MuiCircularProgress-root {
            color: $purple;
          }
          .progress-val {
            position: absolute;
            top: 2rem;
            left: 1.8rem;
            color: $purple1;
            font-size: 1.5rem;
            @include line-height(15, 20);
            font-weight: 500;
          }
        }
      }
      .credit-request {
        font-size: 1.2rem;
        color: $purple1;
        @include line-height(12, 16);
        text-decoration: underline;
        &:hover {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
    .space-name {
      font-size: 1.4rem;
      font-weight: 500;
      @include line-height(14, 20);
      letter-spacing: 0.014rem;
      max-width: 100%;
      word-wrap: break-word;
    }
    .user-link {
      display: inline-flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.4285714286;
      letter-spacing: 0.01em;
      font-weight: 500;
      cursor: pointer;

      .initials,
      .user-avatar {
        @include user-avatar(
          1.2rem,
          500,
          $black1,
          $lightPurple,
          2.4rem,
          2.4rem,
          2.4rem
        );
        img {
          border-radius: 50%;
        }
      }
      .user-name {
        padding-left: 0.8rem;
        padding-right: 0.4rem;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.4rem;
        letter-spacing: 0.14px;
        color: $black1;
        word-break: break-word;
        width: 82%;
        max-width: 18rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.more-members {
          max-width: 15.5rem;
          width: 90%;
          position: relative;
          top: 0.1rem;
          @include bp(lg-max) {
            max-width: 14rem;
          }
        }
        &.one-member,
        &.two-member {
          max-width: 18rem;
          @include bp(lg-max) {
            max-width: 9.2rem;
          }
        }
        &.three-member {
          max-width: 15rem;
          @include bp(lg-max) {
            max-width: 9.2rem;
          }
        }
        .truncated-text {
          display: flex;
        }
      }
    }
    .user-detail {
      padding-left: 1.2rem;
      letter-spacing: 0.014rem;
      color: $black1;
      font-weight: normal;
      .user-name {
        padding-left: 0;
        color: $purple1;
      }
      .description-text {
        .time {
          color: $grey5;
        }
      }
      .htmlContent {
        display: inline;
      }
    }
    .MuiStack-root {
      flex-wrap: wrap;
      margin-bottom: -8px;
      &.purple-chips {
        .MuiChip-root {
          background-color: $lightPurple !important;
          color: $black1 !important;
          font-weight: 500;
        }
      }
      .MuiChip-root {
        margin: 0 0.8rem 0.8rem 0;
        max-width: fit-content;
        background-color: $lightPurple;
        height: 2.4rem;
        font-size: 1.2rem;
        line-height: 2.4rem;
        .MuiChip-label {
          font-weight: 500;
          font-size: 1.2rem;
          //text-transform: capitalize;
          color: $black1;
          max-width: 24rem;
        }
      }
    }
    .recent-activity {
      max-height: 100%;
      // overflow: auto;
      // @include custom-scroll;
      strong {
        font-weight: 500 !important;
        color: $black1;
      }
      &.no-scroll {
        max-height: inherit;
        overflow: inherit;
      }
      .user-link {
        padding: 0.8rem 0;
        font-size: 1.4rem;
        align-items: flex-start;
        line-height: 2rem;
      }
      @include bp(smd-max) {
        display: flex;
        flex-flow: column;
      }
      .time {
        color: $grey5;
        position: relative;
        padding-left: 10px;
        margin-left: 8px;
        font-weight: 400;
        top: 0.1rem;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 3px;
          width: 3px;
          display: block;
          background: $grey5;
          border-radius: 50%;
        }
      }
    }
    .tags {
      max-width: calc(100%);
      .MuiStack-root {
        .MuiButtonBase-root {
          position: relative;
          background: $lightPurple;
          border: 1px solid transparent;
          .MuiChip-label {
            padding: 0.4rem 0.8rem;
            min-width: 5rem;
            text-align: center;
            @extend .transition;
          }
          .MuiChip-deleteIcon {
            opacity: 0;
            font-size: 1rem;
            color: $black1;
            position: absolute;
            right: 0.4rem;
            top: 0.28rem;
            padding: 0.3rem;
            margin: 0;
            line-height: 1;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: $lightPurple;
            border-radius: 100%;
            @include transition(all 0.1s ease-in);
            z-index: 2;
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
            }
            &::before {
              font-size: 1rem;
            }
          }
          &:hover {
            justify-content: flex-start;
            .MuiChip-label {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: calc(90%);
              display: block;
            }
            background: $lightPurple;
            border: 1px solid $purple2;
            cursor: pointer;
            .MuiChip-deleteIcon {
              opacity: 1;
            }
          }
        }
      }
      &.space-tags {
        .MuiChip-label {
          span {
            display: inline !important;
            position: relative;
            top: -0.1rem;
          }
        }
      }
    }
    //Tags
    .search-tag-dropdown-menu {
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15) !important;
      .MuiPaper-root {
        min-width: 17.6rem;
        @include custom-scroll;
        .MuiList-root {
          .MuiButtonBase-root {
            &:hover {
              background: inherit;
              cursor: pointer;
            }
            .MuiStack-root {
              .MuiChip-root {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .base-card-wrap {
    border-bottom: 0.1rem solid grey2;
    margin-bottom: 2.4rem;
    .base-card {
      border: 0.1rem solid $grey2;
      border-radius: 0.8rem;
      padding: 1.6rem;
      .card-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        @include line-height(10, 16);
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        color: $black1;
      }
      .card-title {
        padding: 0.8rem 0 0.2rem;
        color: $black1;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 500;
        @include line-height(14, 20);
        letter-spacing: 0.014rem;
      }
      .sub-heading {
        color: $grey4;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 400;
        @include line-height(12, 16);
        letter-spacing: 0.012rem;
        img,
        .icon {
          margin-right: 0.4rem;
        }
      }
      .button-wrap {
        padding: 1.6rem 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn-join {
          margin: 0 0.8rem 0 0;
          width: calc(100% - 4.2rem);
          background: $purple5;
        }
      }
    }
  }
}

.MuiButtonBase-root {
  &:hover {
    background: inherit;
    cursor: pointer;
  }
}

.owner-row {
  display: block;
  .initials {
    height: 2.4rem;
    width: 2.4rem;
    min-width: 2.4rem;
    border-radius: 50%;
    background: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0.15rem solid $white;
    font-size: 1.2rem;
    color: $black2;
    background-color: $grey6;
    font-weight: 500;
    text-transform: uppercase;
    + .initials {
      margin-left: -0.4rem;
    }
    img {
      border-radius: 100%;
    }
    &.mybgcolor {
      background-color: #e1c3ff;
    }
    &.myforecolor {
      background-color: #ffc7b8;
    }
    &.triadic {
      background-color: #c8f8ff;
    }
  }
  .sub-name {
    display: block;
  }
  .owner-block,
  .members-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
    .title {
      padding-bottom: 0;
      position: relative;
      top: 0.1rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.credit-score {
  .title {
    display: flex;
    justify-content: space-between;
  }
  .title-wrap {
    a {
      text-transform: none;
      font-size: 12px;
    }
  }
  .progress-with-bg .progress-val {
    font-size: 1.5rem;
  }
  .MuiBox-root {
    .MuiLinearProgress-root {
      border-radius: 0;
      background-color: $white;
      border: 1px solid $purple2;
      height: 0.8rem;

      .MuiLinearProgress-bar {
        background-color: $purple2;
      }
    }
  }
  .progress-wrap {
    display: flex;
    align-items: center;
    margin-top: 0.7rem;
    .progress-val {
      font-size: 1.4rem;
      color: $black1;
      font-weight: 400;
      width: 4.5rem;
      text-align: right;
    }
  }
}

.tab-list-wrapper {
  display: flex;
  justify-content: space-between;
  .MuiTabs-root {
    padding-left: 2.4rem;
    margin-top: 1.2rem;
    min-height: auto;
    @include bp(smd-max) {
      margin-top: 7.5rem;
    }
    @include bp(sm-max) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
    .MuiTab-root {
      padding: 0 0 0.8rem 0;
      min-height: auto;
      text-transform: capitalize;
      max-width: none;
      color: $black1;
      min-width: auto;
      margin-right: 4.8rem;
      font-size: 1.6rem;
      @include line-height(16, 24);
      font-weight: 400;
      @include bp(smd-max) {
        margin-right: 3.2rem;
      }
      @include bp(sm-max) {
        margin-right: 2.4rem;
      }
      &:hover:not(.Mui-selected) {
        color: $purple1;
        &::after {
          left: 0;
          right: 0;
          width: 100%;
        }
      }
      &:not(.Mui-selected) {
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          width: 0;
          height: 0.2rem;
          background-color: $purple1;
          @extend .transition;
        }
      }
      &.Mui-selected {
        color: $purple1;
        font-weight: 500;
      }
      .MuiTouchRipple-root {
        visibility: hidden;
      }
    }
  }

  .page-filters-wrapper {
    @include bp(smd-max) {
      right: auto !important;
      left: 1.6rem;
      bottom: 4.6rem !important;
    }
  }
}
.tab-context-wrapper {
  display: flex;
  justify-content: space-between;
  @include bp(smd-max) {
    flex-wrap: wrap;
  }
  .tab-scroll-spacing {
    padding-right: 0.24rem;
    width: calc(100% + 0.8rem);
  }
  .panel-wrapper {
    overflow: hidden auto;
    width: 74%;
    @include bp(smd-max) {
      width: 100% !important;
    }
    //max-height: calc(100vh - 16.2rem) !important;
    .tab-panel-left-section {
      .page-container {
        .page-content {
          max-width: 100%;
        }
      }
    }
  }
  .right-summary-wrapper {
    margin-right: 0.24rem;
    width: calc(26% - 0.24rem);
    height: 100%;
    overflow-y: auto;
    @include bp(smd-max) {
      width: 100% !important;
    }
    .summary {
      width: 100%;
      padding: 1.6rem 1rem 1.6rem 1.6rem;
      @include bp(lg-max) {
        padding: 1.6rem 1.2rem 1.6rem 1.6rem;
      }
    }
  }
}

.sidenav-visible {
  .main-content {
    .tab-context-wrapper {
      .panel-wrapper {
        @include bp(xlg-max) {
          width: 72%;
        }
      }
      .right-summary-wrapper {
        @include bp(xlg-max) {
          width: calc(28% - 0.24rem);
        }
      }
    }
  }
}
